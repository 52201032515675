import React, { FC, useState } from 'react';
import cs from 'classnames';
import { SkeletonRectangle, SkeletonGroup } from '@wix/design-system';

import s from './MobileView.scss';

interface Props {
  dataHook?: string;
  url: string;
  title: string;
}

export const MobileView: FC<Props> = ({ url, title, dataHook }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const previewUrl = `${url}?showMobileView=true`;

  return (
    <div data-hook={dataHook} className={s.display}>
      <div className={cs(s.display, s.device, { [s.loading]: !isLoaded })}>
        {isLoaded ? null : (
          <SkeletonGroup className={s.skeletonGroup}>
            <SkeletonRectangle className={s.skeleton} />
          </SkeletonGroup>
        )}
        <iframe src={previewUrl} title={title} className={s.iframe} onLoad={() => setIsLoaded(true)} />
      </div>
    </div>
  );
};
