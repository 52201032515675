import URL from 'url';
import biLogger from '@wix/web-bi-logger';
import {
  templatesOpenEditorParams,
  templatesActionInViewerSideBarParams,
  clickSaveMobileTemplatesPageParams,
} from '@wix/bi-logger-spettatore-bi/v2/types';
import { StoreTemplate } from '../context/TemplateContext/TemplateContext';

export const biSpettatore = biLogger.factory({ endpoint: 'spettatore_bi' }).updateDefaults({ src: 2 }).logger();
export const biGeneric = biLogger.factory({ endpoint: 'generic' }).updateDefaults({ src: 2 }).logger();
export const biEditorX = biLogger.factory({ endpoint: 'editor-x' }).updateDefaults({ src: 110 }).logger();
const biDefaultLogger = biLogger.factory().logger();

export interface BiOptions {
  siteId?: string;
  originUrl?: string;
  referer?: string;
  editorSessionId: string;
}
let biOptions: BiOptions = { editorSessionId: '' };
export const setBIOptions = (data: BiOptions) => {
  biOptions = data;
};

const getLoadingTime = () => {
  if (typeof window !== 'undefined') {
    const endLoadTime = window.performance?.now();
    const startLoadTime = window.appStartLoadTime;

    if (endLoadTime && startLoadTime) {
      return endLoadTime - startLoadTime;
    }
  }

  return 0;
};

export const reportLoadSuccess = () => {
  biSpettatore.log({
    openingSiteID: biOptions.siteId,
    evid: 43,
    siteType: '2',
    originappurl: biOptions.originUrl,
    loadingTime: Math.round(getLoadingTime()),
  });
};

export const reportMobileView = () => {
  biSpettatore.log({
    evid: 27,
    siteType: '2',
    openingSiteId: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportDesktopView = () => {
  biSpettatore.log({
    evid: 28,
    siteType: '2',
    openingSiteID: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportTabletView = () => {
  biSpettatore.log({
    evid: 98,
    siteType: '2',
    openingSiteID: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportEditorClick = ({ origin }: { origin: 'viewer_top' | 'dealerApi' | 'viewer_more' }) => {
  const requiredFields: templatesOpenEditorParams = {
    openingSiteID: biOptions.siteId!,
    origin,
    esi: biOptions.editorSessionId,
  };

  const legacyFields: templatesOpenEditorParams = {
    originappurl: biOptions.originUrl,
    openingSiteID: biOptions.siteId!,
    siteType: 2,
    template_type: 'Editor',
  };

  biSpettatore.log({ evid: 15, ...requiredFields, ...legacyFields });
};

export const reportInfoClick = () => {
  biSpettatore.log({
    evid: 19,
    siteType: '2',
    openingSiteId: biOptions.siteId,
    originappurl: biOptions.originUrl,
  });
};

export const reportResponsiveTemplateLoaded = () => {
  biEditorX.log({
    evid: 700,
    pageName: 'template_viewer',
    pageUrl: window.location.origin + window.location.pathname,
    origin: biOptions.originUrl || biOptions.referer || 'direct_link',
    templateID: biOptions.siteId || null,
  });
};

export const reportActionButtonClick = (action: any, text: any) => {
  biEditorX.log({
    evid: 707,
    pageName: 'template_viewer',
    pageUrl: window.location.origin + window.location.pathname,
    origin: 'top_bar',
    templateID: biOptions.siteId || null,
    action,
    text,
  });
};

export const reportTemplateEditButtonClick = () => {
  let origin = '';
  const referrer = biOptions.referer || '';
  const parsedUrl = URL.parse(referrer, true);
  const isEditorXHost = parsedUrl.hostname === 'www.editorx.com';
  const isStartPoint =
    isEditorXHost && ['/create/start-point/', '/create/start-point'].includes(parsedUrl.pathname || '');
  const isExpressions = isEditorXHost && ['/templates/', '/templates'].includes(parsedUrl.pathname || '');

  if (isStartPoint) {
    origin = 'start point';
  } else if (isExpressions) {
    origin = 'expressions';
  }

  biEditorX.log({
    evid: 705,
    pageName: 'template_viewer',
    pageUrl: window.location.origin + window.location.pathname,
    origin: 'top_bar',
    template_id: biOptions.siteId || null,
  });

  biGeneric.log({
    evid: 804,
    screen_name: 'viewer',
    action: 'edit',
    origin,
    template_id: biOptions.siteId || null,
    screen_width: screen.width,
    screen_height: screen.height,
  });
};

export const reportStudioTemplateEdit = ({ template }: { template: StoreTemplate }) => {
  biGeneric.log({
    evid: 901,
    action: 'edit',
    template_id: template.templateId,
    origin: biOptions.referer || '',
    tab_name: 'viewer',
    title: template.title,
    slug_id: template.slug,
  });
};

export const reportStudioTemplateGeneralAction = ({
  template,
  action,
  actionValue,
}: {
  template: StoreTemplate;
  action: string;
  actionValue: string;
}) => {
  biGeneric.log({
    evid: 902,
    action,
    action_value: actionValue,
    template_id: template.templateId,
    origin: biOptions.referer || '',
    tab_name: 'viewer',
    title: template.title,
    slug_id: template.slug,
  });
};

export const reportHomeClick = () => reportActionButtonClick('home', '');

interface ActionInSidebarParams {
  actionName: 'pick_site_theme' | 'toggle_theme_sidebar';
  actionValue: string;
}
export const reportActionInSidebar = ({ actionName, actionValue }: ActionInSidebarParams) => {
  const payload: templatesActionInViewerSideBarParams = {
    actionName,
    answerName: actionValue,
    esi: biOptions.editorSessionId,
  };

  biSpettatore.log({
    evid: 108,
    ...payload,
  });
};

export const logSaveTemplateClick = (payload: clickSaveMobileTemplatesPageParams) => {
  biSpettatore.log({
    evid: 105,
    src: 2,
    ...payload,
  });
};

const getWindowParams = () => {
  return typeof window !== 'object'
    ? {}
    : {
        window_width: window.innerWidth,
        window_height: window.innerHeight,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        avail_width: window.screen.availWidth,
        avail_height: window.screen.availHeight,
      };
};

export const logTemplateEdit = ({
  siteId,
  galleryDocIndex,
  openingBrowserUrl,
  metaSiteId,
  editorSessionId,
  hasVideo,
  score,
  currentPageNumber,
  isBestMatch,
}: {
  isBestMatch?: boolean;
  hasVideo?: boolean;
  currentPageNumber?: number;
  galleryDocIndex?: number;
  siteId: string;
  openingBrowserUrl: string;
  metaSiteId: string;
  editorSessionId: string;
  score: number;
}) => {
  const requiredFields: templatesOpenEditorParams = {
    // tpsi
    // category,
    // sub_category,
    page_number: currentPageNumber,
    openingSiteID: siteId,
    metasiteid: metaSiteId,
    is_search_result: true,
    index: galleryDocIndex,
    origin: 'product-page',
    is_recommended: Boolean(isBestMatch),
  };

  const legacyFields: templatesOpenEditorParams = {
    // book,
    // search_guid
    // screen_type,
    // total_search_results,
    openingBrowserUrl,
    esi: editorSessionId,
    btn: true,
    siteType: 2,
    score_vespa: String(score ?? ''),
    template_type: 'editor',
    videoThumbnail: hasVideo,
    openingSiteID: siteId,
    ...getWindowParams(),
  };

  biSpettatore.log({
    evid: 15,
    src: 2,
    ...requiredFields,
    ...legacyFields,
  });
};

export const logTemplateView = ({
  siteId,
  galleryDocIndex,
  openingBrowserUrl,
  editorSessionId,
  hasVideo,
  score,
  currentPageNumber,
  isBestMatch,
  buttonName,
}: {
  isBestMatch?: boolean;
  hasVideo?: boolean;
  currentPageNumber?: number;
  galleryDocIndex?: number;
  siteId: string;
  openingBrowserUrl: string;
  editorSessionId: string;
  buttonName: string;
  score: number;
}) => {
  const requiredFields = {
    page_number: currentPageNumber,
    category: '',
    openingSiteID: siteId,
    is_search_result: true,
    index: galleryDocIndex,
    sub_category: '',
    is_recommended: Boolean(isBestMatch),
    button_name: buttonName,
  };

  const legacyFields = {
    // tpsi
    // book,
    // origin,
    // screen_type
    // search_guid
    // total_search_results
    btn: true,
    siteType: 2,
    openingBrowserUrl,
    esi: editorSessionId,
    score_vespa: String(score ?? ''),
    template_type: 'editor',
    videoThumbnail: hasVideo,
    openingSiteID: siteId,
    ...getWindowParams(),
  };

  biSpettatore.log({
    evid: 14,
    src: 2,
    ...requiredFields,
    ...legacyFields,
  });
};

export const logInstallAppOnThankYouPage = () => {
  biSpettatore.log({ entry_point: 'mobile_flow', evid: 107 });
};

export const logActionOnThankYouPage = (actionName: 'close' | 'back-to-gallery') => {
  biSpettatore.log({ entry_point: 'mobile_flow', action_name: actionName, evid: 106 });
};

export const templatePageLog = ({
  location,
  buttonName,
  templateId,
}: {
  location: 'product_page' | 'header' | 'footer';
  buttonName: string;
  templateId: string;
}) => {
  biDefaultLogger.log({
    src: 2,
    evid: 111,
    button_name: buttonName,
    platform: 'wix',
    location,
    template_id: templateId,
  });
};
